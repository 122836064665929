import React from 'react'
import { Link } from 'react-router-dom'
import project from '../../images/project.svg'
import mekfin from '../../images/mekfin.svg'
import rareheels from '../../images/rareheels.svg'
import budfox from '../../images/budfox.svg'
import link from '../../images/link.svg'
import chgams from '../../images/chgams.svg'
import Readyto from '../../components/readyto'

function Body() {

  const projects = [
    {
      image : chgams,
      project : 'CHGAMS',
      desc : 'Chgams is a stress-free gift-aid management cloud-based software developed to help charity in managing their membership, donations and gift-aid claim process.',
      url : 'https://chgams.com'
    },
    {
      image : budfox,
      project : 'BUDFOX',
      desc : 'Business Website representing the information hub of the fintech budfox app for management of funds and loaning',
      url : 'https://budfox.netlify.app'
    },
    {
      image : rareheels,
      project : 'RAREHEELS',
      desc : 'This app is designed for heels lovers as an e-commerce platform to shop their fovorite footwears',
      url : 'https://rareheels-d5674.web.app/'
    },
  ]
  return (
    <>
      <div className='bg-white grid p-5 lg:p-20 pb-20'>
        <div className='text-center lg:w-[70%] mx-auto flex flex-col space-x-4 grid space-y-3'>
          <span className='text-[#0C0900] text-[32px] lg:text-[54px] leading-[42px] font-[600] lg:leading-[60.4px] mx-auto'>We have proven over time that we can be trusted</span>
          <span className='text-[#3C3C3C] text-[20px] font-[300]'>If you are looking for expert for your datacenter digital transformation <br/>programme, you’ve come to the right place. </span>
          <span className='pt-4'><Link to='/contact' className='bg-[#F5C10A] rounded-[8px] p-3 text-white'>Get in touch</Link></span>
        </div>
      </div>

      <div className='grid grid-cols-4 p-5 lg:p-10 px-5 lg:px-20'>
        <div className='flex flex-col text-start mx-auto'>
          <span className='text-[#0C0900] text-[24px] leading-[34.8px] lg:text-[48px] lg:leading-[64.8px] font-[600]'>40+</span>
          <span className='text-[#393939] text-[14px] leading-[20.3px] lg:text-[18px] lg:leading-[24.3px] font-[300]'>Projects completed</span>
        </div>
        <div className='flex flex-col text-start mx-auto'>
          <span className='text-[#0C0900] text-[24px] leading-[34.8px] lg:text-[48px] lg:leading-[64.8px] font-[600]'>40+</span>
          <span className='text-[#393939] text-[14px] leading-[20.3px] lg:text-[18px] lg:leading-[24.3px] font-[300]'>Happy Clients</span>
        </div>
        <div className='flex flex-col text-start mx-auto'>
          <span className='text-[#0C0900] text-[24px] leading-[34.8px] lg:text-[48px] lg:leading-[64.8px] font-[600]'>15+</span>
          <span className='text-[#393939] text-[14px] leading-[20.3px] lg:text-[18px] lg:leading-[24.3px] font-[300]'>Years in business</span>
        </div>
        <div className='flex flex-col text-start mx-auto'>
          <span className='text-[#0C0900] text-[24px] leading-[34.8px] lg:text-[48px] lg:leading-[64.8px] font-[600]'>5+</span>
          <span className='text-[#393939] text-[14px] leading-[20.3px] lg:text-[18px] lg:leading-[24.3px] font-[300]'>Amazing Teams</span>
        </div>
      </div>

      <div className='bg-white p-5 lg:p-20 lg:px-10 grid grid-cols-3 gap-5 pt-10'>
        {projects.map(({project,desc,image,url}) => {
          return (
            <div className='col-span-3 lg:col-span-1 rounded-[8px] flex flex-col border-[1px] border-[#D5D5D5]'>
              <span className=''><img className='rounded-t-[8px]' src={image} /></span>
              <div className='flex flex-row justify-between p-3 items-center'>
                <span className='flex flex-col text-start space-y-1'>
                  <span className='text-[16px] font-[400] leading-[17.6px] text-[#000000]'>{project}</span>
                  <span className='text-[14px] font-[300] text-[#4E4E4E]'>{desc}</span>
                </span>
                <Link to={url} target='_blank' className='w-full grid'><img className='ml-auto' src={link}/></Link>
              </div>
            </div>
          )
        })}
      </div>

      <div>
        <Readyto></Readyto>
      </div>
    </>
  )
}

export default Body