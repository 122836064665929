import React, { useState } from 'react'
import stars from '../../images/stars.svg'
import phone from '../../images/phone.svg'
import map from '../../images/map.svg'
import email from '../../images/email.svg'
import axios from 'axios'

function Body() {

  const [postData, setPostData] = useState({})
  const makeSubmision = (e) => {
    e.preventDefault();
    axios.post('https://back.goldsalem.com/fxcgfvvb.php',postData,{})
    .then((res) => {
      if(res.data.code == 200){
        alert('Your message has been sent successfully, we will get back to you as soon as possible.')
        window.location.reload();
      }else{
        alert(res.data.message)
      }
    }).catch((e) => {
      alert('Something went wrong, please try again later')
      console.log(e)
    })
  }

  const fillData = (e,field) => {
    setPostData({...postData,[field] : e.target.value})
    console.log(e.target.value)
  }


  return (
    <>
      <div className='flex flex-col space-y-3 text-center p-5 lg:p-20 pb-20 lg:pb-40'>
        <span className='text-[#0C0900] font-[600] text-[32px] leading-[42px] lg:text-[54px] lg:leading-[60.4px] '>Get in Touch</span>
        <span className='text-[#4E4E4E] text-[24px] font-[300] leading-[33.6px]'>Connect with us today to unlock the potential of your digital <br/>journey.</span>
      </div>

      <div className='p-5 lg:p-10 bg-white'>
        <div className='rounded-[8px] gap-y-5 lg:gap-y-0 lg:gap-x-20 p-2 lg:p-6 grid grid-cols-8 border-[1px] border-[#ECECEC] shadow-md mt-[-80px] lg:mt-[-120px] bg-white'>
          <div className='rounded-[8px] rounded-[8px] flex flex-col bg-black col-span-8 lg:col-span-3'>
            <div className='p-5 flex flex-col space-y-10 text-start'>
                <div className='flex flex-col space-y-2'>
                  <span className='text-white font-[600] text-[24px]'>Contact Information</span>
                  <span className='text-[#E4E4E4] text-[14px] font-[300] leading-[18.9px] w-[80%]'>Reach us through any of the following means to bring your ideas to live.</span>
                </div>
                <div className='flex flex-row space-x-3 items-center'>
                  <span><img src={phone} /></span>
                  <span className='flex flex-col text-[16px] leading-[20.64px]'>
                    <span className='text-white font-[400]'>Phone number</span>
                    <span className='text-[#E4E4E4] font-[300]'>+44 7448 690789</span>
                  </span>
                </div>
                <div className='flex flex-row space-x-3 items-center'>
                  <span><img src={map} /></span>
                  <span className='flex flex-col text-[16px] leading-[20.64px]'>
                    <span className='text-white font-[400]'>Location</span>
                    <span className='text-[#E4E4E4] font-[300]'>Newmarket Close, Corby, NN18 8QP</span>
                  </span>
                </div>
                <div className='flex flex-row space-x-3 items-center'>
                  <span><img src={email} /></span>
                  <span className='flex flex-col text-[16px] leading-[20.64px]'>
                    <span className='text-white font-[400]'>Email us at</span>
                    <span className='text-[#E4E4E4] font-[300]'>info@goldsalem.com</span>
                  </span>
                </div>
            </div>
            <span className='ml-auto'><img src={stars} /></span>
          </div>

          <form onSubmit={(e) => makeSubmision(e)} className='col-span-8 lg:col-span-5 flex flex-col space-y-5 text-[#344054] text-[14px] text-start w-full lg:w-[70%] lg:ml-10 my-auto'>
            <div className='flex flex-col w-full space-y-2'>
              <label className=''>Full name</label>
              <input onChange={(e) => fillData(e,'name')} required placeholder='Full name' type='text' className='pl-4 w-full h-[50px] rounded-[8px] outline-none border-[1px] border-[#D0D5DD]' />
            </div>
            <div className='flex flex-col w-full space-y-2'>
              <label className=''>Email</label>
              <input onChange={(e) => fillData(e,'email')} required placeholder='you@yourcompany.com' type='email' className='pl-4 w-full h-[50px] rounded-[8px] outline-none border-[1px] border-[#D0D5DD]' />
            </div>
            <div className='flex flex-col w-full space-y-2'>
              <label className=''>Your message</label>
              <textarea onChange={(e) => fillData(e,'message')} type="text" required className='pl-4 w-full h-[128px] rounded-[8px] outline-none border-[1px] border-[#D0D5DD]' />
            </div>
            <div className=''>
              <button type='submit' className='rounded-[8px] bg-[#F5C10A] text-white w-full h-[50px]'>Send message</button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default Body