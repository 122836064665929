import React from 'react'
import { Link } from 'react-router-dom'
import hero from '../../images/hero.svg'
import arrow from '../../images/arrow.svg'
import software from '../../images/software.svg'
import cloud from '../../images/cloud.svg'
import training from '../../images/training.svg'
import itsolution from '../../images/Itsolution.svg'
import expert from '../../images/expert.svg'
import success from '../../images/success.svg'
import testifier from '../../images/testifier.svg'
import pricilia from '../../images/pricilia.jpeg'
import debbie from '../../images/debbie.jpeg'
import mark from '../../images/mark.jpeg'
import Readyto from '../../components/readyto'

function Body() {
  const expertise = [
    {
      icon : software,
      head : 'Software Development',
      body : 'Experience unparalleled excellence in software development with our commitment to delivering top-tier quality solutions tailored to your unique needs.'
    },
    {
      icon : cloud,
      head : 'Cloud Advisory & Consulting',
      body : 'Our experienced team has ample years of experience in datacenter migration to cloud and provide advice on cloud migration best practice.'
    },
    {
      icon : training,
      head : 'Training Services',
      body : 'With digital evolution, we engage in empowering enterprise and individual to gain insight into cloud adoption and how to make the best of cloud technology.'
    }
  ]

  const testimonials = [
    {
      text : "Goldsalem helped our cloud adoption strategy by providing fit for purpose advice to our senior management team during our Infrastructure Transformation project.",
      name : "Pricilia Doe",
      title : "Cloud Dev",
      testifier : pricilia
    },
    {
      text : "Migrating our on-premises workloads to Azure platform became easy through walk-the-talk exercise provided by experienced Goldsalem Team",
      name : "Mark Hoffman",
      title : "Business Owner",
      testifier : mark
    }
  ]
  return (
    <>
      <div className='flex flex-col space-y-5 grid mt-5 lg:mt-10 pt-5 mb-10 pb-5'>
        <div className='mx-auto'>
          <span className='flex flex-row items-center space-x-3 border-[#8F8F8F] border-[1px] p-3 rounded-[30px] px-5'>
            <span className='rounded-full w-[12px] h-[12px] bg-red-700 items-center grid'><span className='rounded-full mx-auto my-auto animate-blink w-[8px] h-[8px] bg-[#F5C10A] text-[#F5C10A]'></span></span>
            <span className=''>Available for projects</span>
          </span>
        </div>
        <div className='lg:w-[53%] mx-auto'>
            <span className='text-[#0C0900] text-[32px] lg:text-[70px] font-[500] lg:leading-[79.2px]'>Digital Transformation<br/> Consultant</span>
        </div>
        <div className='lg:w-[55%] mx-auto px-5'>
          <span className='text-[#4E4E4E] text-[20px] font-[300] text-center'>If you are looking for expert for your datacenter digital transformation programme, you’ve come to the right place. We can help you in your mission into the Cloud or CoLocation</span>
        </div>
        <div className='mx-auto pt-2'>
          <div className='flex flex-row space-x-4'>
            <Link to='/portfolio' className='bg-[#F5C10A] text-white rounded-[8px] p-3 px-4'>View Projects</Link>
            <Link to='/contact' className='bg-white text-[#F5C10A] border-[#F5C10A] border-[1px] rounded-[8px] p-3 px-4'>Get in touch</Link>
          </div>
        </div>
        <div className='mx-auto pt-5 px-5'>
          <img src={hero} />
        </div>
      </div>

      <div className='flex flex-col space-y-5 grid mt-5 pt-20 pt-5 pb-10 lg:pb-20 bg-white px-5 lg:px-10 grid'>
        <div className='mx-auto bg-black py-10 px-7 py-20 flex flex-col rounded-[20px] text-white space-y-5 lg:space-y-0'>
          <div className='flex flex-col lg:flex-row space-y-3'>
            <span className='text-[32px] leading-[36px] lg:text-[46px] font-[400] text-left leading-[46px]'>We provide various expertise</span>
            <span className='hidden lg:flex'><img src={arrow} /></span>
            <span className='text-[20px] font-[400] text-start leading-[28px] lg:ml-20'>If you are looking for expert for your datacenter digital transformation programme, you’ve come to the right place.</span>
          </div>
          <div className='grid grid-cols-3 lg:space-x-3 space-y-5 lg:space-y-0'>
          {expertise.map(({icon, head, body},id) => {
            return (
              <div key={id} className='col-span-3 mx-auto lg:col-span-1 rounded-[16px] bg-[#141414] flex flex-col text-left p-5 pt-6 pb-10 space-y-3'>
                  <span className=''><img src={icon}/></span>
                  <span className='text-[#FFFFFF] text-[24px] font-[400] leading-[30.95px]'>{head}</span>
                  <span className='text-[#E4E4E4] text-[18px] font-[400] leading-[25.2px]'>{body}</span>
              </div>
            )
          })}
        </div>
        </div>
      </div>

      <div className='bg-white grid grid-cols-2 lg:space-x-5 px-5 lg:px-10 pb-10 lg:pb-20'>
          <div className='col-span-2 lg:col-span-1 mb-5 lg:mb-0 my-auto mb-3 lg:mb-0'><img src={itsolution} /></div>
          <div className='col-span-2 lg:col-span-1 grid grid-cols-1 my-auto text-left space-y-5'>
              <div className='text-[#0C0900] col-span-1 font-[400] text-[32px] leading-[40.2px] lg:text-[56px] lg:leading-[60.2px] lg:ml-[-10%]'>IT Solutions Built for Your Business Needs</div>
              <div className='flex flex-row space-x-4 col-span-1 items-start lg:ml-[-10%]'>
                <img className='' src={expert}/>
                <div className='flex flex-col text-left'>
                  <span className='text-[#0C0900] font-[600] text-[20px]'>Expert IT Solutions, Delivered Affordably</span>
                  <span className='text-[#4C4C4C]'>Our team of IT experts has the knowledge and experience to tackle your business needs. We offer impartial and honest advice, recommending the right IT services at competitive prices. </span>
                </div>
              </div>
              <div className='flex flex-row space-x-4 col-span-1 items-start lg:ml-[-10%]'>
                <img className='' src={success}/>
                <div className='flex flex-col text-left'>
                  <span className='text-[#0C0900] font-[600] text-[20px]'>Your Success is Our Priority</span>
                  <span className='text-[#4C4C4C]'>Here, you'll be heard. We listen to our client requirements and then select the right solution that fits. We care for your business as our own. We take a sincere interest in it and genuinely want to help your company reach its potential.</span>
                </div>
              </div>
          </div>
      </div>

      <div className='bg-white grid grid-cols-2 space-y-8 px-5 lg:px-10 pb-10 lg:pb-20'>
          <div className='col-span-2 flex flex-col text-left lg:w-[50%] space-y-3'>
            <div className='text-[32px] leading-[37.6px] lg:text-[50px] lg:leading-[55.6px]'><p>Customer testimonials</p></div>
            <div className='text-[#0C0900] text-[18px] font-[300] leading-[27px]'><p>Goldsalem truly listened to our needs and developed a solution that perfectly fits our business.</p></div>
          </div>
          <div className='col-span-2 grid grid-cols-2 space-y-5 lg:space-y-0 lg:space-x-5'>
            {testimonials.map(({text,testifier,title,name},id) => {
              return (
                <div key={id} className='col-span-2 lg:col-span-1 rounded-[8px] border-[1px] border-[#D5D5D5] flex flex-col p-6 space-y-5'>
                    <span className='text-left'>"{text}"</span>
                    <div className='flex flex-row items-center space-x-3'>
                      <span><img className='rounded-full w-[61px] h-[61px]' src={testifier}/></span>
                      <span className='flex flex-col text-left'>
                        <span>{name}</span>
                        <span>{title}</span>
                      </span>
                    </div>
                </div>
              )
            })}
          </div>
      </div>

      <Readyto></Readyto>
    </>
  )
}

export default Body