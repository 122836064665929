import React from 'react'
import Header from '../../components/header'
import Footer from '../../components/footer'
import Body from './body'

function Index() {
  return (
    <>
      <Header></Header>
      <Body></Body>
      <Footer></Footer>
    </>
  )
}

export default Index