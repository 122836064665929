import React, { useState } from 'react'
import headlogo from '../images/logo-goldsalem.svg'
import { Link } from 'react-router-dom'

function Header() {

  const [menuOpen, setMenuOpen] = useState(0)
  return (
    <>
      <div className={`flex flex-row justify-between h-[72px] items-center ${menuOpen ? 'lg:border-b-[0.5px]':'border-b-[0.6px]'} border-[#FFDB5A] px-5 lg:px-10`}>
        <Link to='/'><img className='' src={headlogo}/></Link>
        <div className='lg:flex flex-row justify-between space-x-10 hidden'>
          <div className='flex flex-row space-x-7 text-[#555555] font-[300] text-[16px] leading-[24px]'>
            <Link to='/'>Home</Link>
            <Link to='/about'>About</Link>
            <Link to='/portfolio'>Portfolio</Link>
            <Link to='/contact'>Contact</Link>
          </div>
          <div><Link to='/contact' className='rounded-[8px] p-2 bg-[#F5C10A] text-white font-[500] text-[16px] leading-[24px]'>Get in touch</Link></div>
        </div>
        <span className='lg:hidden'>
          <span onClick={() => setMenuOpen(!menuOpen)} className='cursor-pointer bg-[#F5C10A] flex flex-col h-[35px] w-[40px] rounded-[4px] space-y-2 grid p-2'>
            <hr className='bg-white my-auto'/>
            <hr className='bg-white my-auto'/>
            <hr className='bg-white my-auto'/>
          </span>
        </span>
      </div>
      {menuOpen ?
        <div className='lg:hidden flex-col justify-between space-y-4 flex pt-3 border-b-[0.6px] border-[#FFDB5A] pb-8 transition duration-300 ease-in-out' style={{ maxHeight: '200px', overflow: 'hidden' }}>
          <div className='flex flex-col space-y-3 text-[#555555] font-[300] text-[16px] leading-[24px]'>
            <Link to='/'>Home</Link>
            <Link to='/about'>About</Link>
            <Link to='/portfolio'>Portfolio</Link>
            <Link to='/contact'>Contact</Link>
          </div>
          <div><Link to='/contact' className='rounded-[8px] p-2 bg-[#F5C10A] text-white font-[500] text-[16px] leading-[24px]'>Get in touch</Link></div>
        </div> : null
      }

    </>
  )
}

export default Header