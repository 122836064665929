import React from 'react'
import about from '../../images/about.svg'
import story from '../../images/story.svg'
import value from '../../images/values.svg'
import left from '../../images/left.svg'
import right from '../../images/right.svg'
import member from '../../images/member.png'
import member2 from '../../images/member2.png'
import member3 from '../../images/member3.png'
import member4 from '../../images/member4.png'
import israel from '../../images/israel.jpeg'
import lekan from '../../images/lekan.jpeg'
import arrow from '../../images/arrowup.svg'
import twit from '../../images/twit.svg'
import lin from '../../images/lin.svg'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom'
import Readyto from '../../components/readyto'

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div className={`${className} mt-[260px] lg:mt-[400px] 2xl:mt-[410px]`} style={{ ...style, left: '0', right: '20px', zIndex: 1, backgroundImage: `url(${left})`, backgroundSize: 'cover', width: '40px', height: '40px' }} onClick={onClick}>
    </div>
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div className={`${className} mt-[260px] lg:mt-[400px] 2xl:mt-[410px]`} style={{ ...style, left: '60px', zIndex: 1, backgroundImage: `url(${right})`, backgroundSize: 'cover', width: '40px', height: '40px' }} onClick={onClick}>
    </div>
  );
};

function Body() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: false,
    autoplaySpeed: 2000,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    appendDots: (dots) => (
      <div style={{ display: 'flex', justifyContent: 'flex-start', position: 'absolute', bottom: '10px', left: '10px', zIndex: 1 }}>
        <ul style={{ listStyle: 'none', padding: 0 }}>{dots}</ul>
      </div>
    ),
  };

  if (window.innerWidth < 640) {
    settings.slidesToShow = 1;
    settings.slidesToScroll = 1;
  }
  return (
    <>
      <div className='flex flex-col space-y-8 bg-white p-5 lg:p-10'>
        <div className='flex flex-col space-y-3 lg:space-y-0 lg:flex-row justify-between lg:space-x-20'>
          <span className='text-[#0C0900] text-[32px] leading-[42px] lg:text-[54px] font-[500] lg:leading-[60.4px] text-start'>Innovating Business Futures</span>
          <span className='text-[#4E4E4E] font-[300] text-[20px] text-start'>If you are looking for expert for your datacenter digital transformation programme, you’ve come to the right place. </span>
        </div>
        <div>
          <img src={about} />
        </div>
      </div>

      <div className='grid grid-cols-2 bg-white p-5 lg:p-10 items-start space-y-3 lg:space-y-0'>
        <div className='col-span-2 lg:col-span-1 flex flex-row space-x-4 items-center'>
          <span className='text-[32px] leading-[42px] lg:text-[46px] leading-[51.6px] text-[#0C0900]'>Our Story</span>
          <span className=''><img className='w-[50%] lg:w-full' src={story} /></span>
        </div>
        <div className='col-span-2 lg:col-span-1 flex flex-col text-left space-y-4 text-[#4E4E4E] text-[20px] leading-[28px] text-start font-[300]'>
          <span className='text-[#333333] font-[400]'>Founded in 2014, Goldsalem has become a trusted partner for businesses aiming to streamline operations and achieve cost efficiency with cloud technology. We specialize in seamless cloud adoption, driving impactful digital transformations, developing custom business applications, and offering expert advisory services tailored to meet the unique needs of each client.</span>
          <span>Our Specializations Include:</span>
          <span className=''>Cloud Adoption: We help businesses seamlessly migrate their operations to the cloud by leveraging our expertise in industry best practices and advanced technologies. Our goal is to ensure a smooth transition that minimizes disruption and maximizes efficiency. </span>
          <span className=''>Digital Transformation: Staying competitive in today’s fast-paced world requires embracing digital transformation. Our team guides organizations through this journey, uncovering opportunities for innovation, efficiency, and growth while aligning with their strategic goals. </span>
          <span>Custom Business Application Development: We specialize in creating custom business applications designed to meet your unique needs. Our team combines industry expertise with innovative technology to deliver tailored solutions that streamline operations, enhance productivity, and support your business goals.
            Whether you need a web-based platform, a mobile app, or an enterprise-grade solution, we focus on delivering user-friendly, scalable, and efficient applications that drive results. With our commitment to quality and client satisfaction, we help businesses transform ideas into powerful tools for success.</span>
          <span>Cloud Advisory: As trusted partners, we collaborate with clients to create robust cloud strategies tailored to their business objectives. Our services cover every stage, from initial planning to continuous optimization, ensuring lasting success.</span>
          <span>Training & Mentoring: Empowering your team is essential to fully leveraging cloud technology. We provide comprehensive training programs and ongoing mentoring, equipping your staff with the skills and confidence to thrive in a cloud-centric environment.</span>
          <span>Cloud First Strategy: We advocate for a "Cloud-First" mindset, encouraging businesses to prioritize cloud solutions whenever possible. By adopting this approach, organizations can achieve enhanced agility, scalability, and cost efficiency, unlocking their full potential.</span>
          <span className='flex flex-col space-y-2'>
            <span className='text-[#000000] font-[400]'>Vision.</span>
            <span className=''>At Goldsalem, our vision is to be recognized globally as a leader in providing exceptional IT consultancy and training services. We are dedicated to driving our clients' success by boosting productivity and strengthening their competitive edge through innovative solutions and unmatched expertise.</span>
          </span>
          <span className='flex flex-col space-y-2'>
            <span className='text-[#000000] font-[400]'>Mission.</span>
            <span className=''>Our mission is to empower clients in designing and implementing cloud adoption strategies that align seamlessly with their unique goals. By delivering cost-effective, customized, and impactful solutions, we help organizations unlock the full potential of cloud technology, fostering growth, efficiency, and long-term success.</span>
          </span>
        </div>
      </div>

      <div className='grid grid-cols-2 p-5 lg:p-10 gap-5 bg-white pb-10'>
        <span className='col-span-2 text-[32px] text-start lg:text-[46px] text-[#0C0900] font-[400] leading-[51.6px] lg:mx-auto lg:mb-8'>Our  Core Values</span>
        <div className={`col-span-2 lg:col-span-1 flex flex-col bg-[#FFFBF2] px-5 py-10 space-y-8 text-start rounded-[8px]`}>
          <span><img src={value} /></span>
          <span className='flex flex-col space-y-3'>
            <span className='text-[#0C0900] text-[24px] font-[400] leading-[26.4px]'>Delivering the best solutions</span>
            <span className='text-[#373737] text-[16px] font-[300]'>We ensured to consistently provide unparalleled solutions, tailored precisely to meet your requirements and exceed expectations.</span>
          </span>
        </div>
        <div className={`col-span-2 lg:col-span-1 flex flex-col bg-[#F6FAFF] px-5 py-10 space-y-8 text-start rounded-[8px]`}>
          <span><img src={value} /></span>
          <span className='flex flex-col space-y-3'>
            <span className='text-[#0C0900] text-[24px] font-[400] leading-[26.4px]'>Honest services</span>
            <span className='text-[#373737] text-[16px] font-[300]'>We pride ourselves on delivering honest services, transparently guiding our clients through every step of their journey to ensure trust and integrity.</span>
          </span>
        </div>
        <div className={`col-span-2 lg:col-span-1 flex flex-col bg-[#F0FFF1] px-5 py-10 space-y-8 text-start rounded-[8px]`}>
          <span><img src={value} /></span>
          <span className='flex flex-col space-y-3'>
            <span className='text-[#0C0900] text-[24px] font-[400] leading-[26.4px]'>We care for your business just like ours</span>
            <span className='text-[#373737] text-[16px] font-[300]'>We prioritize your success as if it were our own, offering unwavering dedication and personalized attention to every aspect of your business needs.</span>
          </span>
        </div>
        <div className={`col-span-2 lg:col-span-1 flex flex-col bg-[#FFF3F1] px-5 py-10 space-y-8 text-start rounded-[8px]`}>
          <span><img src={value} /></span>
          <span className='flex flex-col space-y-3'>
            <span className='text-[#0C0900] text-[24px] font-[400] leading-[26.4px]'>Help business grow</span>
            <span className='text-[#373737] text-[16px] font-[300]'>Our mission is to empower businesses to thrive and expand by providing innovative solutions and expert guidance tailored to their growth objectives.</span>
          </span>
        </div>
      </div>

      {/* <div className='flex flex-col space-y-8 bg-[#F9FAFB] p-5 lg:p-10 pb-20'>
        <span className='text-[#0C0900] text-[32px] leading-[32px] lg:text-[46px] lg:leading-[46px] font-[400] text-start lg:w-[40%]'>We’re a fast-growing team</span>
        <div className='lg:pb-10'>
          <Slider {...settings}> */}
          {/* <div className="relative slick-slide-item">
            <img src={member} alt="Image" className="lg:w-[95%]"/>
            <div className="absolute left-0 right-0 bottom-0 flex lg:w-[95%] h-[50%] p-5">
              <div className="absolute inset-0 bg-gradient-to-r from-gray-500 via-gray-400 to-gray-800 opacity-75"></div>
              <div className="relative z-10 text-white w-full flex flex-col space-y-2 lg:space-y-5 text-start">
                <span className="text-[24px] lg:text-[30px] font-[600] leading-[38px] flex flex-row justify-between">
                  <span>Alisa Hester</span>
                  <span><img src={arrow} /></span>
                </span>
                <span className='flex flex-col space-y-1 lg:space-y-3'>
                  <span className="text-[18px] font-[600] leading-[24px]">Founder & CEO</span>
                  <span className="text-[16px] font-[400] leading-[24px]">Former co-founder of Opendoor. Early staff at Spotify and Clearbit.</span>
                </span>
                <span className='flex flex-row space-x-5 items-center'>
                  <Link to='https://twitter.com' className=""><img src={twit} /></Link>
                  <Link to='https://linkedin.com' className=""><img src={lin} /></Link>
                </span>
              </div>
            </div>
          </div> */}

          {/* <div className="relative slick-slide-item">
            <img src={israel} alt="Image" className="lg:w-[95%]"/>
            <div className="absolute left-0 right-0 bottom-0 flex lg:w-[95%] h-[50%] p-5">
              <div className="absolute inset-0 bg-gradient-to-r from-gray-500 via-gray-400 to-gray-800 opacity-75"></div>
              <div className="relative z-10 text-white w-full flex flex-col space-y-2 lg:space-y-5 text-start">
                <span className="text-[24px] lg:text-[30px] font-[600] leading-[38px] flex flex-row justify-between">
                  <span>Israel Akin-Akinsanya</span>
                  <span><img src={arrow} /></span>
                </span>
                <span className='flex flex-col space-y-1 lg:space-y-3'>
                  <span className="text-[18px] font-[600] leading-[24px]">Engineering</span>
                  <span className="text-[16px] font-[400] leading-[24px]">Chief Technology Officer</span>
                </span>
                <span className='flex flex-row space-x-5 items-center'>
                  <Link to='https://twitter.com' className=""><img src={twit} /></Link>
                  <Link to='https://www.linkedin.com/in/israel-akin-akinsanya-773987186/' className=""><img src={lin} /></Link>
                </span>
              </div>
            </div>
          </div> */}

          {/* <div className="relative slick-slide-item">
            <img src={lekan} alt="Image" className="lg:w-[95%]"/>
            <div className="absolute left-0 right-0 bottom-0 flex lg:w-[95%] h-[50%] p-5">
              <div className="absolute inset-0 bg-gradient-to-r from-gray-500 via-gray-400 to-gray-800 opacity-75"></div>
              <div className="relative z-10 text-white w-full flex flex-col space-y-2 lg:space-y-5 text-start">
                <span className="text-[24px] lg:text-[30px] font-[600] leading-[38px] flex flex-row justify-between">
                  <span>Olalekan Daramola</span>
                  <span><img src={arrow} /></span>
                </span>
                <span className='flex flex-col space-y-1 lg:space-y-3'>
                  <span className="text-[18px] font-[600] leading-[24px]">Products</span>
                  <span className="text-[16px] font-[400] leading-[24px]">Senior Product Manager.</span>
                </span>
                <span className='flex flex-row space-x-5 items-center'>
                  <Link to='https://twitter.com' className=""><img src={twit} /></Link>
                  <Link to='https://www.linkedin.com/in/daramolaolalekan/' className=""><img src={lin} /></Link>
                </span>
              </div>
            </div>
          </div> */}

          {/* <div className="relative slick-slide-item">
            <img src={member4} alt="Image" className="lg:w-[95%]"/>
            <div className="absolute left-0 right-0 bottom-0 flex lg:w-[95%] h-[50%] p-5">
              <div className="absolute inset-0 bg-gradient-to-r from-gray-500 via-gray-400 to-gray-800 opacity-75"></div>
              <div className="relative z-10 text-white w-full flex flex-col space-y-2 lg:space-y-5 text-start">
                <span className="text-[24px] lg:text-[30px] font-[600] leading-[38px] flex flex-row justify-between">
                  <span>Johnny Bell</span>
                  <span><img src={arrow} /></span>
                </span>
                <span className='flex flex-col space-y-1 lg:space-y-3'>
                  <span className="text-[18px] font-[600] leading-[24px]">Frontend Developer</span>
                  <span className="text-[16px] font-[400] leading-[24px]">Former frontend dev for Linear, Coinbase, and Postscript.</span>
                </span>
                <span className='flex flex-row space-x-5 items-center'>
                  <Link to='https://twitter.com' className=""><img src={twit} /></Link>
                  <Link to='https://linkedin.com' className=""><img src={lin} /></Link>
                </span>
              </div>
            </div>
          </div> */}

        {/* </Slider>
        </div>
      </div> */}

      <div className='pt-10 bg-white'>
        <Readyto></Readyto>
      </div>
    </>
  )
}

export default Body 